// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-welcome {
  font-family: sans-serif;
  margin: 0 auto;
  max-width: 550px;
}
#single-spa-welcome h2,
#single-spa-welcome h3,
#single-spa-welcome p,
#single-spa-welcome ul,
#single-spa-welcome ol,
#single-spa-welcome pre {
  margin: 0;
  margin-bottom: 0.6em;
}

#single-spa-welcome li {
  margin-bottom: 0.4em;
}

#single-spa-welcome pre {
  background: #eef;
  overflow-y: scroll;
  padding: 0.6em;
}

#single-spa-welcome a {
  color: #01f;
  font-weight: bold;
  text-decoration: none;
}

#single-spa-welcome a:hover {
  text-decoration: underline;
}

#single-spa-welcome a:visited {
  color: #02a;
}

#single-spa-welcome aside {
  border-left: 3px solid #01f;
  font-size: 0.9rem;
  margin-top: 0.4em;
  padding-left: 1ch;
}

#single-spa-welcome .logo {
  display: block;
  margin-right: 1rem;
  width: 60px;
}

#single-spa-welcome .banner {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 1rem 0;
}

#single-spa-welcome .center {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/welcome.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,cAAc;EACd,gBAAgB;AAClB;AACA;;;;;;EAME,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["#single-spa-welcome {\n  font-family: sans-serif;\n  margin: 0 auto;\n  max-width: 550px;\n}\n#single-spa-welcome h2,\n#single-spa-welcome h3,\n#single-spa-welcome p,\n#single-spa-welcome ul,\n#single-spa-welcome ol,\n#single-spa-welcome pre {\n  margin: 0;\n  margin-bottom: 0.6em;\n}\n\n#single-spa-welcome li {\n  margin-bottom: 0.4em;\n}\n\n#single-spa-welcome pre {\n  background: #eef;\n  overflow-y: scroll;\n  padding: 0.6em;\n}\n\n#single-spa-welcome a {\n  color: #01f;\n  font-weight: bold;\n  text-decoration: none;\n}\n\n#single-spa-welcome a:hover {\n  text-decoration: underline;\n}\n\n#single-spa-welcome a:visited {\n  color: #02a;\n}\n\n#single-spa-welcome aside {\n  border-left: 3px solid #01f;\n  font-size: 0.9rem;\n  margin-top: 0.4em;\n  padding-left: 1ch;\n}\n\n#single-spa-welcome .logo {\n  display: block;\n  margin-right: 1rem;\n  width: 60px;\n}\n\n#single-spa-welcome .banner {\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  margin: 1rem 0;\n}\n\n#single-spa-welcome .center {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
